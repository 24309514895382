// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "@fortawesome/fontawesome-free/js/all";
import "chartkick/chart.js"
import Toastify from 'toastify-js'
import LocalTime from "local-time"
LocalTime.start()

// document.addEventListener("turbo:before-render", (event) => {
//   console.log("Turbo: before-render", event);
// });

// document.addEventListener("turbo:render", (event) => {
//   console.log("Turbo: render", event);
// });


// Create a custom helper function for toasts
window.showToast = function(message, type = '') {
  const options = {
    text: message,
    duration: 2000,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
    className: `toast-${type}`,
    style: {
      borderRadius: '8px',
      padding: '10px',
    }
  }
  
  if (type === 'success') {
    options.style.background = "linear-gradient(to right, #4caf50, #81c784)"; // Green gradient
  } else if (type === 'danger') {
    options.style.background = "linear-gradient(to right, #f44336, #e57373)"; // Red gradient
  } else if (type === 'error') {
    options.style.background = "#FF0033";
  } else if (type === 'info') {
    options.style.background = "linear-gradient(to right, #2196f3, #64b5f6)"; // Blue gradient
  } else if (type === 'warning') {
    options.style.background = "linear-gradient(to right, #ff9800, #ff5722)"; // Yellow to orange gradient
  } else {
    options.style.background = "linear-gradient(to right, #424242, #616161)"; // Dark gray to charcoal gradient for default
  }
  
  Toastify(options).showToast()
}
