import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  connect() {
    if (!this.choices) {
      this.initializeChoices();
    }
  }

  initializeChoices() {
    this.choices = new Choices(this.element, {
      removeItemButton: true,
      searchEnabled: true,
      itemSelectText: "",
      maxItemCount: 5,
      placeholderValue: "Search by name",
      noResultsText: "No results found",
      shouldSort: false,
    });

    this.element.addEventListener("search", this.fetchResults.bind(this));
  }

  async fetchResults(event) {
    const query = event.detail.value; // The search query from the Choices.js search input
    if (!query) return;

    try {
      const response = await fetch(`/stars/search?query=${encodeURIComponent(query)}`, {
        headers: { Accept: "application/json" },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Update the Choices instance with the new data
      this.choices.clearChoices();
      this.choices.setChoices(
        data.map((star) => ({ value: star.slug, label: star.name })),
        "value",
        "label",
        false
      );
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  }

  redirect(event) {
    const selectedStarId = event.target.value;
    if (selectedStarId) {
      Turbo.visit(`/stars/${selectedStarId}`);
    }
  }
}
